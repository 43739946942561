<template lang="pug">
v-dialog(v-model="showing" :max-width="500" no-click-animation origin @input="onModalShowing" data-test="label-dialog-wrapper")
  template(v-slot:activator="{ on }")
    slot(name="activator" :on="on")
      v-btn(v-on="on" data-test="label-btn" outlined small :class="{'full-width': fullWidth}").btn-common Create label
  v-card(data-test="label-modal")
    v-card-title Label creating
    v-card-text
      v-text-field(
        v-model="model.name"
        label="Name"
        data-test="label-name"
        :error="formErrors.hasError('name')"
        :error-messages="formErrors.fields.name"
      )
      //- v-text-field(:value="formData.color" label="Name" readonly)
      .d-flex.justify-center
        v-color-picker(v-model="color" @input="setColor" mode="hexa" data-test="label-color" hide-mode-switch)
    v-card-actions
      v-btn(small color="error" @click="remove" v-if="value" :loading="deleting" data-test="label-delete") delete
      slot(name="actions" :on="toggle" :item="value")
      v-spacer
      v-btn(color="error" text @click="toggle" data-test="label-cancel") Close
      v-btn(color="success" text @click="submit" :loading="loading" data-test="label-submit") {{ submitButtonText }}
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import FormErrors from "@/util/form-errors"
import LabelModel from "@/app/admin/models/LabelModel"
import LabelService from "@/app/admin/models/LabelService"
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],

  props: {
    value: null,
    fullWidth: Boolean
  },

  data: () => ({
    showing: false,
    formErrors: new FormErrors(),
    model: new LabelModel(),
    loading: false,
    deleting: false,
    color: '#218347'
  }),

  computed: {
    submitButtonText() {
      if(this.value) return 'update'
      return 'create'
    }
  },

  mounted() {
    this.setColor(this.color)
    // this.setDefault()
  },
  methods: {
    toggle() {
      this.showing = !this.showing
    },

    setColor(color) {
      this.model.color = color
    },

    onModalShowing(isShowing) {
      if(!isShowing) {
        this.reset()
        return
      }
      this.mergeModel()
    },

    reset() {
      this.model.reset()
      this.setColor(this.color)
    },

    async mergeModel() {
      if(this.value) {
        this.model.name = this.value.name
        this.model.ID = this.value.ID
        await this.$nextTick()
        this.color = this.value.color
        this.model.color = this.value.color
      }
    },

    submit() {
      if(!this.model.validate(this.formErrors)) {
        this.$notify({text: 'invalid fields', type: 'error'})
        return
      }
      if(this.value) {
        this.update()
        return
      }
      this.send()
    },

    async send() {
      try {
        this.loading = true
        let res = await LabelService.create(this.model.getApiData())
        this.$notify({text: 'Label created', type: 'success'})
        this.toggle()
        this.$emit('created', res)
        this.reset()
        this.loading = false
      } catch (error) {
        this.loading = false
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.model.apiAttributes,
          showErrorModal: false
        });
      }
    },

    async update() {
      try {
        this.loading = true
        let res = await LabelService.update(this.value.ID, this.model.getApiData())
        this.$notify({text: 'Label updated', type: 'success'})
        this.toggle()
        this.$emit('updated', this.model)
        this.reset()
        this.loading = false
      } catch (error) {
        this.loading = false
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.model.apiAttributes,
          showErrorModal: false
        });
      }
    },

    async remove() {
      try {
        this.deleting = true
        let res = await LabelService.remove(this.value.ID)
        this.$notify({text: 'Label deleted', type: 'success'})
        this.toggle()
        this.$emit('deleted', this.value.ID)
        this.deleting = false
      } catch (error) {
        this.deleting = false
        this.processError(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
        });
      }
    }
  },

  components: {
  }
}
</script>
